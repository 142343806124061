@import '../../styles/variables';

.root {
  padding: 0 20px;
  font-size: 12px;
  border-top: 1px solid $graySuperLight;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px; 
  margin-top: 20px;

  &.inReply,
  &.isVisitor {
    padding-top: 0px;
    border-top: none;
    padding-bottom: 20px; 
  }
}

.displayName {
  font-weight: 700;
}

.title {
  color: $grayDark;
}

.isText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleWrapper {
  & > *:not(:first-child)::before {
    content: ' • ';
  }
}
