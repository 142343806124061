@use "sass:map";
@import './colors';

$breakpoints: (
  xs: 0,
  small: 43rem,
  medium: 48rem,
);

$container-padding-x: var(--container-padding-x);

$focus-outline-width: 2px;
$focus-outline: $focus-outline-width solid $blue;

:root {
  --container-padding-x: 0.75rem;
}

@media (min-width: map.get($breakpoints, medium)) {
  :root {
    --container-padding-x: 1.25rem;
  }
}

$z-index-notifications: 20;
$z-index-notifications-overlay: 15;
$z-index-pending-post-stream: 14;
$z-index-content-higher: 10;
$z-index-content-medium: 3;
$z-index-content-low: 1;
