@import '../../styles/colors';

.root {
  position: relative;

  & > img,
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > img {
    border-radius: 50%;
    overflow: hidden;
    background: $white;
  }
}

.small {
  width: 15px;
  height: 15px;
  line-height: 15px;
}

.medium {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.large {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.extraLarge {
  width: 200px;
  height: 200px;
  line-height: 200px;
}
