.root {
  display: flex;
}

.input {
  display: inline-block;
  flex: 1 0 auto;
  margin-right: 10px;
}

.copyButtonWrapper {
  margin-top: 12px;
}

.button {
  white-space: nowrap;
}
