@import '../../styles/_colors';

.root {
  background-color: $highlightsBox;
  margin-top: 24px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding: 10px;

  border-top: 1px solid $lightCoolGray;
}

.buttonText {
  margin-left: 6px;
}

.visibility {
  height: 20px;
  width: 20px;
}

.list {
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0 20px 14px;
}
