.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.rightButtons {
  flex: 0 0 auto;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.leftButtons {
  flex: 1 0 auto;
}
