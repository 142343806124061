@import '../../styles/variables';
@import '../../styles/mixins';

.header {
  margin-top: 0;
  margin-bottom: 20px;
}

.isActiveWrapper {
  text-align: right;
}
.isVisitorPostingEnabled {
  text-align: right;
  margin-top: 0.5em;
}

.fieldsetElement {
  border: 1px solid $gray;
  border-radius: 4px;
}

.fieldsetElement + .fieldsetElement {
  margin-top: 20px;
}

.legend {
  color: $gray;
  font-size: 0.8125rem;
  padding: 0 4px;
  margin-left: -5px;
}

.radioInput {
  position: relative;
  display: flex;
  padding: 0.25rem 0;
  align-items: center;
}

.radioInputElement {
  position: absolute;
  display: block;
  top: 0;
  opacity: 0;
  /* Add if not using autoprefixer */
  /* -webkit-appearance: none; */
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

.radioLabelText {
  display: block;
}

.radioShadowElement {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.radioShadowElement:after {
  display: block;
  content: '';
  height: 0.6875rem;
  width: 0.6875rem;
  border-radius: 50%;
  background: $gray;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.radioInputElement:checked + .radioShadowElement {
  border-color: $extraDarkTurquoise;
}

.radioInputElement:checked + .radioShadowElement:after {
  background: $extraDarkTurquoise;
  opacity: 1;
}

.radioInputElement:focus + .radioShadowElement {
  @include focusOutline;
}
