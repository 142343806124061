@use "sass:map";
@import '../../styles/variables';

@media (max-width: map.get($breakpoints, medium)) {
  .root {
    padding-bottom: 224px;
  }
}

.postStream {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
