@use "sass:map";
@import '../styles/variables';

.root {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: $black;

  @media (max-width: map.get($breakpoints, medium)) {
    background-image: unset !important;
  }
}

.box {
  width: 100%;
  height: 100%;
  justify-content: center;

  background-color: rgba($megaDarkTurquoise, 0.8);
  backdrop-filter: blur(15px);
  padding: 75px 34px 120px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: map.get($breakpoints, medium)) {
    width: 360px;
    height: 446px;
    justify-content: space-between;
    border-radius: 8px;
  }
}

.logoWrapper {
  width: 90px;
}

.direktcenterTitle {
  color: $white;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.56px;
}

.direktcenterDesc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $white;
  text-align: center;
}

.footerContainer {
  position: absolute;
  bottom: 40px;
  color: $white;
  width: 60px;
}

.footerList {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  cursor: default;
}

.textWrapper {
  margin-bottom: 40px;
  cursor: default;
}

.footerListItem {
  font-size: 14px;
  white-space: nowrap;
}

.creditBox {
  position: absolute;
  right: 40px;
  bottom: 40px;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: rgba($black, 0.25);
  backdrop-filter: blur(16px);
  color: $white;
  cursor: default;

  @media (max-width: map.get($breakpoints, medium)) {
    display: none;
  }
}

.creditText {
  font-weight: bold;
}
