@import '../../styles/colors';
@import '../../styles/variables';

.titleWithIcon {
  display: flex;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
    position: relative;
    color: $red;
    margin-left: -10px;
  }

  span {
    margin-left: 6px;
  }
}

.root {
  display: flex;

  button {
    text-indent: -10000px;
    height: 36px;
    padding: 0;

    svg {
      position: absolute;
      top: 9px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .leftButton {
    width: 42px;
    margin-right: 1px;

    svg {
      height: 15px;
      width: 15px;
      margin-left: 2px;

      path {
        fill: $red;
      }
    }
  }

  .rightButton {
    width: 36px;
    vertical-align: bottom;

    svg {
      height: 10px;
      width: 10px;
      top: 12px;
      margin-left: -1px;
    }
  }
}

.removeActionsWrapper {
  position: relative;
}

.actionList {
  position: absolute;
  left: 0;
  right: unset;
  z-index: $z-index-content-higher;
  background-color: white;
}
