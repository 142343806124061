@use "sass:map";
@import '../../styles/variables';

.root {
  display: none;
  color: $white;
  padding: 0.375rem 1rem;
  border-radius: 0.9375em;
  transition: background-color 0.1s linear;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.1);
  }

  @media (min-width: map.get($breakpoints, medium)) {
    display: block;
  }
}
