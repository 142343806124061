@import '../styles/variables';

.root {
  max-width: 960px;
  padding: 20px;
  margin: 0 auto;
}

.loaderAbsolute {
  position: relative;
  width: 200px;
  height: 200px;
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
  background: $yellowExtraLight;
}

.moreActionsWrapper {
  position: relative;
  height: 100px;
}

.moreActions {
  position: absolute;
  right: 0;
}
