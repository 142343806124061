// @use "sass:map";
@import '../../styles/variables';

.root {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  padding: 3rem 1.2rem 2rem;

  & > * {
    max-width: 60rem;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &.withAside {
    overflow: auto;
  }

  // @media (max-width: map.get($breakpoints, medium)) {
  //   &.withAside {
  //     // we need to compensate for the height of the aside that contains pending
  //     // posts but if/when we add more elements to the main container or change
  //     // the container type this might stop working.
  //     margin-bottom: 15vh;
  //   }
  // }
}

.loading {
  margin-left: auto;
  margin-right: auto;
}
