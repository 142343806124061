@import '../../styles/variables';

.root {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-bottom: 20px;
}

.label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 4px;
  font-size: 13px;
  line-height: 20px;
  color: $gray;
  background-color: $white;
  border-radius: 4px;
  transition: 0.1s linear color;

  .input:focus + & {
    color: $black;
  }
}

.input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid $gray;
  border-radius: 4px;

  .hasError & {
    border-color: $red;
  }

  .hasError &:focus {
    outline: 2px solid $red;
  }

  &::placeholder {
    font-style: italic;
    line-height: normal;
    color: $grayLight;
  }

  &[readonly] {
    background-color: $grayExtraLight;
  }
}

.error {
  padding-top: 5px;
  font-size: 13px;
  font-weight: 700;
  color: $redDark;
}
