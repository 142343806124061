@use 'sass:color';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.label {
  display: block;
}

.input {
  display: block;
  margin-bottom: 10px;
}

.imageFileInputWrapper {
  position: relative;
  height: 220px;
  margin-bottom: 10px;
  text-align: center;
  background-color: color.adjust($extraLightTurquoise, $lightness: 7%);
  border: 2px dashed $regularCoolGray;
  border-radius: 5px;
  transition: background-color 0.1s linear;

  &:focus-within {
    @include focusOutline;
  }

  &:not(.isUploading):hover,
  &:not(.isUploading):focus-within {
    background-color: $extraLightTurquoise;
  }
}

.imageFileInputLabel {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 100px 10px;
  line-height: 20px;
  text-decoration: underline;
}

.imageFileInput {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.imageHidden {
  display: none;
}

.imageInput:focus + .imageLabel {
  @include focusOutline;
}
