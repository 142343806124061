@import '../../styles/variables';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  flex: 1 0 0;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-word;
}

.actionButtons {
  margin-left: 20px;
}

.button {
  margin-left: 10px;
}

.isGuestSettingsDisabled {
  display: none;
}
