.buttons {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 2rem;
  margin-bottom: -1.5rem;

  > div {
    margin-bottom: 1.5rem;
  }
}

.leftButtons {
  flex: 0 0 auto;
}

.rightButtons {
  flex: 0 0 auto;
  margin-left: auto;

  > *:not(:last-child) {
    margin-right: 10px;
  }
}
