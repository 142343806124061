$black: #333;
$white: #fff;

// TODO: rensa de som inte används
$blueExtraLight: #d2e6f5;
$blueLight: #7dbee1;
$blue: #2891c8;
$blueDark: #1e69aa;
$blueExtraDark: #0f418c;
$blueAccent: #0091ff;

$greenExtraLight: #d2ebd2;
$greenLight: #82c882;
$green: #32a532;
$greenDark: #23821e;
$greenExtraDark: #0f5a0a;
$greenAccent: #00c800;

$redExtraLight: #ffd2d7;
$redLight: #f0828c;
$red: #e13241;
$newsRed: #e02e3d;
$redDark: #b9232d;
$redExtraDark: #911414;
$redAccent: #ff4b7d;
$redDirektcenter: #ff5b51;

$graySuperLight: #f0f0f0;
$grayExtraLight: #ebebeb;
$grayLight: #ccc;
$gray: #8c8c8c;
$grayDark: #737373;
$grayExtraDark: #4d4d4d;

$yellowExtraLight: #fff0d2;
$yellowLight: #ffdc8c;
$yellow: #ffc346;
$yellowDark: #ebaa23;
$yellowExtraDark: #d28c00;
$yellowAccent: #fff000;

$orangeRegular: #eb6937;

$purpleDark: #641e78;
$purpleExtraLight: #f7f6fc;

// Nya färger från design
$superLightCoolGray: #f3f5f5;
$extraLightCoolGray: #e6ebeb;
$lightCoolGray: #d2dcdc;
$regularCoolGray: #7d9196;
$darkCoolGray: #647378;
$extraDarkCoolGray: #465055;
$highlightsBox: #cad3d3;

$extraLightTurquoise: #cdf0e6;
$lightTurquoise: #87d7cd;
$regularTurquoise: #3cbeb4;
$darkTurquoise: #2d9b91;
$extraDarkTurquoise: #197869;
$megaDarkTurquoise: #0d3c35;
$accentTurquoise: #00dcd2;

$direktcenterColor: #186656;

/* Added colors */
$grayDarker: #5c5c5c;
$highlightsBorderCoolGray: #adb9bb;
$loginPageBylineHeaderCoolGray: #819196;
