@import '../../../../styles/variables';

.videoAppInfo {
  font-size: 0.8125rem;
  color: $grayDark;
}

.videoInfoPanel {
  display: flex;
  flex-direction: column;
  background-color: $superLightCoolGray;
  margin-bottom: 1em;
  margin-top: -4px;
}

.videoInfoHeadingWrapper {
  display: flex;
  align-items: center;
  padding: 20px 16px 0;
}

.videoInfoHeading {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.infoIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-top: -3px;
}

.id {
  flex-grow: 1;
  max-width: 50%;
}

.idLabel {
  font-weight: 600;
  display: block;

  @media (min-width: 950px) {
    display: inline;
  }
}

.videoInfo {
  padding: 12px 16px 20px 48px;
  width: 100%;
  display: flex;
  font-size: 14px;
}
