@import '../../styles/colors';

.root {
  background-color: $extraLightCoolGray;
  color: $black;
}

.logo {
  margin: 32px auto;
  width: 60px;
}

.copy {
  text-align: center;
  margin-bottom: 32px;
}
