@import '../../styles/_colors';
@import '../../styles/variables';

.root {
  width: auto;
}

.head {
  text-align: center;
  background: $direktcenterColor;
  color: $white;
  padding: 0.25rem;
  font-weight: 700;
  font-size: 0.75rem;
}

.body {
  width: auto;
  min-height: 250px;
  padding: 20px;

  h2 {
    margin-top: 0;
  }

  p {
    margin: 0;
  }
}

.displayName {
  font-weight: 700;
}

.title {
  font-size: 15px;
}

.location {
  color: $grayDark;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
  }
}

.footer {
  text-align: right;
}

.footer * + * {
  margin-left: 0.5rem;
}

.warning {
  padding: 10px 20px 10px 20px;
  margin: 20px 20px 1rem 20px;
  text-align: center;
  background: $yellowExtraLight;
  border: 1px solid $yellowLight;
}
