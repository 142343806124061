@use "sass:map";
@import '../../styles/variables';

.header {
  position: relative;
  z-index: $z-index-content-low;
}

.headerContent {
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  color: $white;
  background: $black;
  border-top: 1px solid #000;
  box-shadow: 0 1px 0 rgba($white, 0.25) inset;
}

.headerTitle {
  margin: 0;
  font-size: 1rem;

  @media (max-width: map.get($breakpoints, medium)) {
    user-select: none;
  }
}

.pauseIndicator {
  position: absolute;
  top: 10px;
  right: 20px;

  @media (max-width: map.get($breakpoints, medium)) {
    right: 40px;
  }
}

.arrowIcon {
  display: block;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  transform: rotate(180deg);

  &.isRotated {
    transform: rotate(0deg);
  }
}

.toggleButton {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background: transparent;
  color: $white;
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: map.get($breakpoints, medium)) {
    display: none;
  }
}
