@import '../styles/variables';

.root {
  padding: 24px;

  display: flex;
  flex-direction: column;
}
.footer {
  margin-top: 8px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.header {
  margin-top: 0;
  margin-bottom: 20px;
}

.fieldsetElement {
  border: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radioInput {
  display: flex;
  position: relative;
}

.extraLabelWrapper {
  margin-left: 8px;
  position: relative;
}

.radioInputElement {
  margin-right: 16px;
}

.tooltip {
  position: absolute;
  top: 25px;
  left: 120px;
  transform: translateX(-50%);
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;

  &::after {
    right: unset;
    left: 33px;
  }

  .extraLabelWrapper:hover & {
    opacity: 1;
  }
}

.manifestInputWrapper {
  margin-top: 8px;
}
