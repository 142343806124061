@import '../../styles/variables';

.root {
  display: block;
  margin-bottom: 10px;
}

.hex {
  display: block;
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 25px;
}

.text {
  height: 48px;
  font-family: monospace;
  font-size: 18px;
  line-height: 48px;
  color: $extraDarkCoolGray;
}
