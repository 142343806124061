@import '../../styles/variables';

.root {
  position: relative;
  display: block;
  width: 100%;
}

.padding {
  padding: 20px;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.spinnerWrapper {
  overflow: hidden;
}

.spinner {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 7px solid $grayExtraLight;
  border-right: 7px solid $grayExtraLight;
  border-bottom: 7px solid $grayExtraLight;
  border-left: 7px solid $darkTurquoise;
  border-radius: 50%;
  transform: translateZ(0);
  animation: load8 1.5s infinite linear;

  &::after {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}

.text {
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
