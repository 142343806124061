@use "sass:map";
@import '../../styles/variables';

.root {
  margin-bottom: 20px;
}

.rootError {
  background: $redExtraLight;
}

.label {
  padding: 0.9375rem 1.25rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  color: $black;
  background: $white;
  border-bottom: 1px solid $graySuperLight;
}

.labelError {
  color: $white;
  background: $redDark;
}

.body {
  padding: 1rem 0.75rem;
  background: $white;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 1.25rem;
  }
}

.bodyNoPadding {
  padding: 0;
}

.bodyTransparent {
  background-color: transparent;
}

.notification {
  display: inline-block;
  padding: 3px 5px 2px;
  margin-top: -3px;
  margin-left: 5px;
  font-size: 0.7em;
  color: $white;
  vertical-align: top;
  background: $red;
  border-radius: 10px;
}
