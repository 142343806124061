@use "sass:map";
@import '../../styles/variables';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  background-color: $black;
}

.isGuest {
  background-color: $direktcenterColor;
}

.logo {
  flex: 0 0;
  margin-right: 1.66667em;
  padding: 0.75rem $container-padding-x;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;

  @media (min-width: map.get($breakpoints, medium)) {
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  a {
    position: relative;
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.slash {
  margin-right: 0.83333em;
  font-weight: 900;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-self: stretch;
  justify-content: flex-end;
  max-width: 75%;
  margin-left: auto;
  min-width: 0;
}

.userInfo {
  position: relative;
  display: flex;
  align-self: stretch;
  max-width: 100%;
}

.userActions {
  position: absolute;
  top: calc(100% - 0.357rem);
  right: $container-padding-x;
  z-index: $z-index-content-higher;
  @media (min-width: map.get($breakpoints, medium)) {
    top: calc(100% - 0.625rem);
  }
}

.displayNameButton {
  display: flex;
  align-items: center;
  align-self: stretch;
  max-width: 100%;
  padding: 0.25rem $container-padding-x;
  font-size: 0.875rem;
  font-weight: bold;
  color: $white;
  text-align: left;
  background: transparent;
  border: 0;
  transition: background-color 0.1s linear;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.1);
    outline-offset: -$focus-outline-width;
  }

  @media (min-width: map.get($breakpoints, medium)) {
    font-size: 1rem;
  }
}

.avatar {
  display: block;
  flex: 0 0 auto;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.375rem;
  background: $grayLight;
  border-radius: 50%;

  @media (min-width: map.get($breakpoints, medium)) {
    width: 1.75rem;
    height: 1.75rem;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.displayName {
  overflow: hidden;
  display: none;

  @media (min-width: map.get($breakpoints, medium)) {
    display: block;
  }
}

.downArrowIcon {
  flex: 0 0 auto;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  fill: currentColor;
}

.logoutLink {
  color: $white;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
}

.navItem {
  margin-right: 20px;
  font-size: 16px;
  line-height: 30px;
  color: $black;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}
