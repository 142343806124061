@import '../../styles/variables';

.root {
  position: relative;
}

.actionList {
  position: absolute;
  top: calc(100% - 0.5rem);
  right: 0.375rem;
  z-index: $z-index-content-medium;
  background-color: white;
}
