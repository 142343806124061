@import '../../styles/_colors';
@import '../../styles/variables';

$timestampWidth: 7.5rem;

.root {
  list-style-type: none;
  padding: 0;
}

.heading {
  display: inline-block;
  margin: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.timestamp {
  flex-shrink: 0;
  width: $timestampWidth;
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  line-height: 20px;

  time {
    color: $grayDarker;
    font-size: 12px;
  }
}

.iconWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.headingWrapper {
  flex-grow: 1;
  min-height: 40px;
}
.postWrapper {
  margin-left: $timestampWidth - 1.125rem + 0.5rem;
}

.listItem {
  border-top: 1px solid $highlightsBorderCoolGray;
  display: flex;
  align-items: flex-start;
  text-align: left;
  width: 100%;

  &:first-child {
    border-top: none;
  }
}

.warningWrapper {
  min-width: 20px;
  flex-shrink: 0;
  align-self: stretch;
  padding: 0 8px;
  margin-right: -8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.warning {
  height: 16px;
  align-self: stretch;
  color: $orangeRegular;
  position: relative;
}

.tooltip {
  position: absolute;
  top: 22px;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;
  right: -30px;
  min-width: 200px;

  &::after {
    left: unset;
    right: 33px;
  }

  .warningWrapper:hover & {
    opacity: 1;
  }
}
