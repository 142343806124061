@import '../../styles/variables';

.root {
  margin: 0;
}

.figCaption {
  display: block;
  padding: 10px;
  background-color: $graySuperLight;

  .pinned & {
    background-color: $white;
  }
}

.video {
  display: block;
}
