@use "sass:map";
@import '~normalize.css/normalize.css';

@import './variables';
@import './mixins';
@import './fonts';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}

body {
  min-width: 20rem;
  font-family: Publik, Helvetica, Arial, 'Nimbus Sans L', 'Bitstream Vera Sans',
    sans-serif;
  font-size: 1rem;
  color: $black;
  word-wrap: break-word;
  overflow-wrap: break-word;
  background: $lightCoolGray;
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

:focus {
  @include focusOutline;
}

a {
  color: $blueDark;
}

p {
  margin-top: 0;
}

button {
  cursor: pointer;
}

input,
textarea {
  background-clip: padding-box;
}

code {
  padding: 0.0625rem 0.1875rem;
  font-size: 85%;
  color: $grayExtraLight;
  background-color: $grayExtraDark;
  border-radius: 0.25rem;
}

.visuallyhidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

// Override header sizes from normalize
h1 {
  font-size: 1.25rem;

  @media (min-width: map.get($breakpoints, medium)) {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 1.125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
