@import '../../styles/colors';
@import '../../styles/variables';

.displayNameAndTitle {
  position: relative;
}

.displayName {
  word-break: break-word;
  font-weight: 700;
}

.title {
  color: $grayDark;
}

.listItem {
  border-top: 1px solid $grayExtraLight;
  margin: 0px -20px;
  padding: 8px 20px;

  &:last-of-type {
    border-bottom: 1px solid $grayExtraLight;
  }
}

.openedListItem {
  background-color: $graySuperLight;

  & label span {
    background-color: $graySuperLight;
  }
}

.removeButton {
  border: 0;
  margin-left: -2px;
  background-color: inherit;

  svg {
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;

    path {
      fill: $extraDarkTurquoise;
    }
  }

  &:hover svg path {
    fill: $darkTurquoise;
  }
}

.showLinkButton {
  background-color: transparent;
  border: 0;
  color: $extraDarkTurquoise;
  font-weight: bold;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.moderationInfo {
  margin-top: 1rem;
}

.downArrowIcon {
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 8px;
  top: 1px;
  position: relative;
  fill: $extraDarkTurquoise;
}

.showLinkButtonOpen {
  & .downArrowIcon {
    transform: rotate(180deg);
  }
}

.listItemHeader {
  display: flex;
  align-items: center;
}

.nameTitleRemove {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
}

.tooltip {
  position: absolute;
  top: 22px;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;
  right: -65px;
  min-width: 200px;

  .displayNameAndTitle:hover & {
    opacity: 1;
  }
}
