@font-face {
  font-family: Publik;
  font-style: normal;
  font-weight: 300;
  src: url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Light_5.woff2')
      format('woff2'),
    url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Light_5.woff')
      format('woff');
}

@font-face {
  font-family: Publik;
  font-style: normal;
  font-weight: 700;
  src: url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Bold_5.woff2')
      format('woff2'),
    url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Bold_7.woff')
      format('woff');
}

@font-face {
  font-family: Publik;
  font-style: normal;
  font-weight: 400;
  src: url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Regular_3.woff2')
      format('woff2'),
    url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Regular_5.woff')
      format('woff');
}

@font-face {
  font-family: Publik;
  font-style: normal;
  font-weight: 900;
  src: url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Black_3.woff2')
      format('woff2'),
    url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Black_5.woff')
      format('woff');
}

@font-face {
  font-family: Publik;
  font-style: italic;
  font-weight: 400;
  src: url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Italic_3.woff2')
      format('woff2'),
    url('https://www.svtstatic.se/resources/svtlib-font/fonts/PublikWeb-Italic_5.woff')
      format('woff');
}
