@import '../../styles/variables';

.link {
  padding: 0.625rem 1.2rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
  border: 0;
  border-radius: 1.125rem;
  transition: background-color 0.1s linear;
  appearance: none;
  color: $black;
  background: $highlightsBox;
  box-shadow: 0 2px 1px rgba($color: $black, $alpha: 0.15);
  cursor: grab;

  svg {
    height: 1.125rem;
    margin: 0 6px 0 0;
    vertical-align: bottom;
  }

  &:hover {
    background: $superLightCoolGray;
    box-shadow: 0px 0px 20px 2px rgba($color: $black, $alpha: 0.15);
  }

  &:active {
    cursor: grabbing;
  }
}
