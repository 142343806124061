@import '../../styles/mixins';
@import '../../styles/variables';

.root {
  cursor: pointer;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.rail {
  background-color: $grayLight;
  border-radius: 14px;
  display: inline-block;
  height: 28px;
  line-height: normal;
  position: relative;
  transition: background-color 0.2s linear;
  vertical-align: middle;
  width: 50px;

  &.labelRight {
    margin-right: 10px;
  }

  &.labelLeft {
    margin-left: 10px;
  }
}

.input:focus ~ .rail {
  @include focusOutline;
}

.variantGreen {
  .input:checked ~ .rail {
    background: $extraDarkTurquoise;
  }
}

.variantGray {
  & .input:checked ~ .rail {
    background: $extraDarkCoolGray;
  }
}

.ball {
  background-color: $white;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba($black, 0.3);
  display: block;
  height: 24px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: transform 0.2s ease-out;
  width: 24px;
}

.input:checked ~ .rail > .ball {
  transform: translateX(22px);
}

.onLabel,
.offLabel {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.onLabel {
  display: none;
}

.input:checked ~ .onLabel {
  display: inline-block;
}

.input:checked ~ .offLabel {
  display: none;
}
