.root {
  padding: 70px 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  svg {
    height: 33px;
    width: 33px;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    text-align: center;
    line-height: 27px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    text-align: center;
    line-height: 24px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;
}
