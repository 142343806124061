.root {
  padding: 0;
  border: 0;
}

.removeButton {
  margin-bottom: 10px;
}

.legend {
  //visually hidden
  display: none;
}
