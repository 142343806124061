@use 'sass:color';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.label {
  display: block;
}

.input {
  display: block;
  margin-bottom: 10px;
}

.quickVideoFileInputWrapper {
  position: relative;
  margin-bottom: 10px;
  text-align: center;
  background-color: color.adjust($extraLightTurquoise, $lightness: 7%);
  border: 2px dashed $regularCoolGray;
  border-radius: 5px;
  transition: background-color 0.1s linear;

  &:focus-within {
    @include focusOutline;
  }

  &:not(.isUploading):hover,
  &:not(.isUploading):focus-within {
    background-color: $extraLightTurquoise;
  }
}

.quickVideoFileInputLabel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 10px 10px;
  line-height: 20px;
  align-items: center;
}

.uploadFileInfoDescription {
  text-decoration: underline;
}

.uploadFileInfoDescriptionList {
  text-align: left;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  color: $gray;
  font-size: 0.875rem;
}

.quickVideoFileInput {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.video {
  height: 100%;
  background: $grayLight;
}

.videoWrapper {
  position: relative;
  height: 220px;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
}

.videoOverlayText {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
