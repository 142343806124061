@import '../../styles/variables';

.root {
  border-collapse: collapse;
  //   width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  //   table-layout: fixed;

  .annoying {
    color: orange;
    font-weight: bold;
  }
  .threat {
    color: red;
    font-weight: bold;
  }
  .blockedAuto,
  .blockedCustom {
    color: orangered;
    font-weight: bold;
  }

  td,
  th {
    padding: 0.75rem 4px;
    text-align: left;
    vertical-align: top;
    border-top: 1px solid $grayExtraLight;
    border-bottom: 1px solid $grayExtraLight;
  }

  tr:hover {
    background-color: $grayExtraLight;
  }

  thead th {
    font-size: 0.75rem;
    font-weight: normal;
    color: $grayDark;
    vertical-align: bottom;
    border: 0;
    cursor: pointer;
  }

  .idCell {
    text-align: right;
  }
}

time {
  white-space: nowrap;
}

.tooltipTrigger {
  position: relative;
}
.truncated {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset -14px 0px 10px -10px #aaa;
  margin: -12px 2px;
  padding: 12px 0;
  min-height: 40px;
}

.tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  z-index: $z-index-content-low;
  opacity: 0;
  transition: opacity 0.2s linear;
  transform: translateX(-50%);

  .tooltipTrigger:hover & {
    opacity: 1;
  }
}
