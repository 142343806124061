@import '../../styles/_colors';

.root {
  position: relative;
  display: inline-block;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  appearance: none;
  background: none;
  border-radius: 0;

  &:hover,
  &:active {
    background: none;
    text-decoration: underline;
  }
  &:disabled {
    color: $gray;
  }
  &:hover:disabled {
    text-decoration: none;
  }
}
