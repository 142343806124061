@use "sass:map";
@import '../../../styles/variables';
@import '../../Post/PostContent.module';

.root {
  color: $black;
  background-color: $white;
  position: relative;
}

.editorToolbar {
  font-size: 1rem;
  background-color: $extraLightCoolGray;
  border: 1px solid $regularCoolGray;
  border-bottom: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.editor {
  min-height: 6rem;
  padding: 0.75rem;
  line-height: 1.38;
  border: 1px solid $regularCoolGray;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 15px;
  }

  &:focus-within {
    padding: calc(0.75rem - 1px);

    @media (min-width: map.get($breakpoints, medium)) {
      padding: 15px - 1px;
    }
  }

  .hasError & {
    border-color: $red;
  }

  .hasError &:focus {
    outline: 2px solid $red;
  }

  p:first-child > span:only-child strong {
    @include postPseudoHeadingStyle();
  }
}

.toolbarHidden {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.charCounter {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px 6px 2px;
  border-radius: 3px;
  font-size: 0.875rem;
}

.charCounterSmall {
  background-color: $graySuperLight;
}

.charCounterMedium {
  background-color: $yellowLight;
}

.charCounterLarge {
  background-color: $red;
  font-weight: bold;
  color: $white;
}

.tooltip {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 106px;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;

  .charCounter:hover & {
    opacity: 1;
  }
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.isNewline) {
  &::before {
    content: '↵';
    color: $blueAccent;
    margin-left: 1px;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;
  }
}
