@import '../../styles/colors';
.root {
  display: flex;
  padding: 1.5rem;
  background-color: $yellowExtraLight;

  & > * + * {
    margin-left: 1rem;
  }
}

.ribbon {
  svg {
    vertical-align: text-top;
    width: 1rem;
    height: 1rem;
  }
}
