@import '../../styles/variables';

.dim {
  background-color: $grayLight;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-notifications-overlay;
  opacity: 0.5;
}
