@import '../../styles/variables';
@import '../../styles/fonts';

.root {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-bottom: 20px;
}

.label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 4px;
  font-size: 13px;
  line-height: 20px;
  color: $gray;
  background-color: $white;
  border-radius: 4px;
  transition: 0.1s linear color;
  cursor: default;

  .selectWrapper:focus-within + & {
    color: $black;
  }
}

.select {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid $gray;
  border-radius: 4px;
  cursor: pointer;
  background-color: $white;
  font-family: Publik, Helvetica, Arial, 'Nimbus Sans L', 'Bitstream Vera Sans',
    sans-serif;

  .hasError & {
    border-color: $red;
  }

  .hasError &:focus {
    outline: 2px solid $red;
  }

  &[readonly] {
    background-color: $grayExtraLight;
  }

  appearance: none;
}

.arrowDown {
  position: absolute;
  right: 15px;
  height: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.error {
  padding-top: 5px;
  font-size: 13px;
  font-weight: 700;
  color: $redDark;
  cursor: default;
}

.selectWrapper {
  position: relative;
}
