@use 'sass:color';
@import '../../styles/variables';

.root {
  position: relative;
  display: block;
  width: auto;
  height: 20px;
  padding: 0;
  padding-right: 25px;
  cursor: pointer;
  background: none;
  border: none;

  &:disabled {
    cursor: default;
  }
}

.label {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;

  .active & {
    color: color.adjust($green, $lightness: 30%);
  }

  .inactive & {
    color: $grayLight;
  }

  .autoPaused & {
    color: $yellowLight;
  }

  .manuallyPaused & {
    color: $redLight;
  }
}

.activeIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
}

.activeIconBall1,
.activeIconBall2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color.adjust($green, $lightness: 30%);
  border-radius: 50%;
  opacity: 0.6;
  animation: bounce 2s infinite ease-in-out;
}

.activeIconBall2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.pauseIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  .autoPaused & {
    background-color: $yellowLight;
  }

  .manuallyPaused & {
    background-color: $redLight;
  }
}

.inactiveIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: $gray;
  border-radius: 50%;
  opacity: 1;
}

.inactiveIconIcon {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  background: $white;
}

.autoPauseIcon {
  background-color: $yellow;
}

.pauseIconLine1,
.pauseIconLine2 {
  position: absolute;
  top: 5px;
  width: 2px;
  height: 10px;
  background: $black;
}

.pauseIconLine1 {
  left: 7px;
}

.pauseIconLine2 {
  right: 7px;
}
