@import '../../styles/variables';

.root {
  margin: 0;
}

.img {
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.figCaption {
  display: block;
  padding: 10px;
  background-color: $graySuperLight;
  .pinned & {
    background-color: $white;
  }
}
