@use "sass:map";
@import '../styles/variables';

.header {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 30px;
  margin-top: 14px;
  justify-content: space-between;

  @media (min-width: map.get($breakpoints, small)) {
    flex-direction: row;
    align-items: center;
  }
}

.headerLeft {
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    flex: 1;
  }

  @media (min-width: map.get($breakpoints, small)) {
    gap: 20px;
    flex-direction: row;
    max-width: 460px;
  }
}

.headerRight {
  width: 100%;

  @media (min-width: map.get($breakpoints, small)) {
    margin-bottom: 0;
    max-width: 160px;
  }
}

.empty {
  text-align: center;
  min-height: 200px;
  padding: 88px 20px;
  border-radius: 4px;
  border: 1px solid $grayExtraLight;
}

.bookmarkletContainer {
  display: none;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
  text-align: center;

  @media (min-width: map.get($breakpoints, medium)) {
    display: inline-block;
  }
}

.bookmarkletTextContainer {
  margin-top: 30px;

  span {
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  svg {
    vertical-align: middle;
    height: 1.125rem;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: -2px;
    margin-bottom: 1px;
  }
}
