@use 'sass:color';
@import '../../styles/variables';

.root {
  left: 50%;
  min-width: 280px;
  max-width: 600px;
  padding: 10px 30px 10px 20px;
  margin-bottom: 1rem;
  color: $white;
  text-align: center;
  list-style-type: none;
  border: 1px solid color.adjust($gray, $lightness: 10%);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s opacity, 0.3s transform ease-in-out;
  transform: translateX(-50%);

  @media (min-width: 680px) {
    width: 600px;
  }
}

.rootError {
  background: $red;
}

.rootInfo {
  background: $black;
}

.rootSuccess {
  background: $green;
}

.rootClosing {
  opacity: 0;
  transform: translate(-50%, -80px);
}

.message {
  margin: 0;
}

.close {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 30px;
  height: 30px;
  padding: 0;
  color: $white;
  background: transparent;
  border: none;
  appearance: none;
}

.closeIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
}
