@use "sass:map";
@import '../../styles/variables';

.root {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  table-layout: fixed;

  @media (min-width: map.get($breakpoints, medium)) {
    font-size: 1rem;
  }

  @media (max-width: map.get($breakpoints, medium) - 0.0625rem) {
    thead {
      display: none;
    }

    tr {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0.75rem;
      padding-left: 2rem;
      border-top: 1px solid $grayExtraLight;

      .indicator,
      .indicatorActive {
        position: absolute;
        top: 0.8125rem;
        left: 0.75rem;
      }

      &:last-child {
        border-bottom: 1px solid $grayExtraLight;
      }
    }

    td:not(:last-child),
    th:not(:last-child) {
      margin-bottom: 0.25rem;
    }

    th:last-child,
    td:last-child {
      color: $grayDark;
      font-size: 0.75rem;

      &::before {
        content: 'Skapad: ';
      }
    }
  }

  @media (min-width: map.get($breakpoints, medium)) {
    td,
    th {
      padding: 1rem 0.5rem;
      text-align: left;
      border-top: 1px solid $grayExtraLight;
      border-bottom: 1px solid $grayExtraLight;
    }

    thead th {
      font-size: 0.75rem;
      font-weight: normal;
      color: $grayDark;
      vertical-align: bottom;
      border: 0;
      text-transform: uppercase;
    }

    th:last-child,
    td:last-child {
      text-align: right;
    }

    th:first-child {
      width: 50%;
    }

    td:first-child {
      text-align: left;
      padding-right: 16px;
    }
  }

  time {
    white-space: nowrap;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.indicator {
  position: relative;
  top: 0.125rem;
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.75rem;
  color: $white;
}

.indicatorActive {
  color: $newsRed;
  position: relative;
  top: 0.125rem;
  left: -1px;
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.75rem;
}

.tooltip {
  position: absolute;
  top: 24px;
  left: 50%;
  z-index: $z-index-content-low;
  opacity: 0;
  transition: opacity 0.2s linear;
  transform: translateX(-50%);
  min-width: 72px;

  .indicator:hover &,
  .indicatorActive:hover & {
    opacity: 1;
  }
}

.tableWrapper {
  display: flex;
}

.tableRow {
  &:hover {
    background: #f4f6f6;
  }
}

.disasterLabel {
  background: $redExtraLight;
  border-radius: 0.25rem;
  padding: 0.225rem 0.375rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
