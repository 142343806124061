@use "sass:math";
@import '../../styles/variables';

.root {
  margin-bottom: 10px;
  background-color: $gray;
  background-size: cover;
}

.pillarBox {
  margin-left: auto;
  margin-right: auto;
  width: percentage(math.div(9, 16));
  min-width: 10rem;
  max-width: 100%;
}
