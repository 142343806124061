@import '../../styles/variables';

.root {
  padding: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: monospace;
  font-size: 16px;
  color: $extraDarkCoolGray;
  background: $extraLightCoolGray;

  &:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
