@import '../../styles/colors';

.root {
  margin-right: 0.5rem;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  outline: inherit;
  border-radius: 50%;
}

.small {
  margin-right: 0.25rem;
}

.previewContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
