@use "sass:map";
@import '../../styles/variables';

.root {
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-content-higher;

  @media (min-width: map.get($breakpoints, medium)) {
    position: -webkit-sticky;
    position: sticky;
  }
}
