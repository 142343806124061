@import '../../styles/variables';

.root {
  margin-bottom: 20px;
  background: $white;
  border: 2px solid $extraDarkCoolGray;
}

.title {
  padding: 10px;
  margin: 0;
  font-family: monospace;
  font-size: 18px;
  color: $white;
  cursor: pointer;
  background: $extraDarkCoolGray;
}

.toggle,
.link {
  position: relative;
  width: 21px;
  height: 21px;
  font-weight: bold;
  color: $white;
  background: transparent;
  border: 0;
}

.link {
  float: left;
  margin-right: 10px;
}

.toggle {
  float: right;
}

.toggleOpen {
  transform: rotate(180deg);
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  margin: 3px;
  fill: $white;
}

.gray {
  background: $lightCoolGray;
}

.slim {
  max-width: 415px;
}

.content {
  padding: 20px;
}
