@use "sass:map";
@import '../../styles/variables';

.root {
  position: relative;
  max-width: 32rem;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-content-low;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: $white;
  opacity: 0;
}

.loadingWrapperVisible {
  pointer-events: auto;
  opacity: 0.8;
  transition: opacity 0.2s linear;
}

.body {
  padding: 0.75rem 0.75rem 0.5rem;
  background-color: $extraLightCoolGray;
  border-radius: 4px 4px 0 0;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 20px 16px 16px 16px;
  }
}

.message {
  margin-bottom: 0.5rem;

  @media (min-width: map.get($breakpoints, medium)) {
    margin-bottom: 10px;
  }
}

.meta {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: $grayDark;
  list-style-type: none;

  li {
    display: inline-block;

    &::after {
      margin: 0 5px;
      content: '|';
    }

    &:last-child::after {
      content: '';
    }
  }
}

.button {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &.busy * {
    opacity: 0.5;
  }
}

.loader {
  display: block;
  margin: 0 auto;
}

.toolbar {
  display: flex;
  padding: 0.75rem;
  background: $extraLightCoolGray;
  border-top: 1px solid $lightCoolGray;
  border-radius: 0 0 16px 16px;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 8px 16px;
  }
}

.toolbarButtonsLeft {
  flex: 1 0 auto;
  display: flex;
  margin-right: 10px;
}

.toolbarButtonsRight {
  display: flex;
}

.replyEditor {
  padding: 20px;
  background-color: $extraLightCoolGray;
}

.actionsWrapper {
  float: right;
  margin: -12px;
  padding-left: 20px;
  padding-bottom: 20px;

  @media (max-width: map.get($breakpoints, medium)) {
    padding-right: 8px;
    padding-top: 8px;
  }
}

.updatePostEditorWrapper {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  background-color: $extraLightCoolGray;
}

.reportedMessageContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $yellowExtraLight;

  z-index: 3;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.reportedMessageThreat {
  background-color: $redExtraLight;
}

.reportedMessage {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 12px;
}

.reportedMessageHeading {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: map.get($breakpoints, medium)) {
    display: inline;
    font-size: 14px;
    line-height: 20px;

    // Space before the rest of the text
    &::after {
      content: ' ';
    }
  }
}

.reportedMessageBlockTime,
.reportedMessageUrgent {
  font-size: 14px;
  line-height: 14px;

  @media (max-width: map.get($breakpoints, medium)) {
    display: inline;
    line-height: 20px;
  }
}

.reportedMessageUrgent {
  font-style: italic;
  margin-bottom: 5px;
}

.reportedMessageButtons {
  margin-top: 10px;
  @media (max-width: map.get($breakpoints, medium)) {
    margin-top: 9px;
  }
}
