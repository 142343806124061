@import '../../styles/variables';

.button {
  position: relative;
  display: inline-block;
  padding: 0.625rem 1.2rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  background-color: $white;
  border: 0;
  border-radius: 1.125rem;
  transition: background-color 0.1s linear;
  appearance: none;
  text-align: center;

  svg {
    height: 1.125rem;
    margin: 0;
    vertical-align: bottom;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

.primary {
  @extend .button;

  color: $white;
  background: $extraDarkTurquoise;

  &:hover {
    background: $darkTurquoise;
  }
}

.secondary {
  @extend .button;

  color: $black;
  background: $lightCoolGray;

  &:hover {
    background: darken($lightCoolGray, 10%);
  }
}

.danger {
  @extend .button;

  color: $white;
  background: $redDark;

  &:hover {
    background: $redExtraDark;
  }
}

.dark {
  @extend .button;

  color: $white;
  background: $grayExtraDark;

  &:hover {
    background: $black;
  }
}

.light {
  @extend .button;

  color: $black;
  background: $white;

  &:hover {
    background: $superLightCoolGray;
  }
}

.active {
  @extend .button;

  color: $white;
  background: $darkCoolGray;

  * {
    fill: $white;
  }

  &:hover {
    background: $regularCoolGray;
  }
}

.link {
  position: relative;
  display: inline-block;
  padding: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  appearance: none;
  color: $black;
  background: none;
  border-radius: 0;

  &:hover,
  &:active {
    background: none;
    text-decoration: underline;
  }
}

.fullWidth {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.round {
  position: relative;
  width: 36px;
  height: 36px;
  padding: 0;
  text-indent: -10000px;

  svg {
    position: absolute;
    top: 9px;
    height: 18px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.leftRound {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rightRound {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.big {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 12px 32px;
  margin: 0 auto;
  font-size: 16px;
  border-radius: 21px;
  box-shadow: 0 4px 2px rgba($color: $black, $alpha: 0.15);
}

.tooltip {
  position: absolute;
  top: 46px;
  left: 50%;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;
  transform: translateX(-50%);

  .button:hover & {
    opacity: 1;
  }
}
