.root {
  display: flex;
  overflow: auto;
  flex: 1;
  width: 100%;
  flex-direction: column;

  &.withAside {
    flex-direction: row;
  }
}
