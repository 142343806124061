@import '../../../styles/variables';

.item {
  position: relative;
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0.125rem 1px 0.125rem 0;
  color: $black;
  background: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 100ms, border-color 100ms, background-color 100ms;

  &:first-child {
    margin-left: 0.1875rem;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $regularCoolGray;
    background-color: $white;
    z-index: $z-index-content-low;
  }

  &:active {
    background-color: $extraLightCoolGray;
  }
}

.itemActive {
  color: $blueExtraDark;
  background-color: $blueExtraLight;
  border-color: $regularCoolGray;

  &:hover,
  &:focus,
  &:active {
    background-color: $blueExtraLight;
    z-index: $z-index-content-low;
  }

  &:active {
    background-color: $blueLight;
    background-color: $extraLightCoolGray;
  }
}

.icon {
  width: 1rem;
  height: 1rem;
  margin: auto;
}

.tooltip {
  position: absolute;
  top: 40px;
  left: 50%;
  z-index: $z-index-content-low;
  opacity: 0;
  transition: opacity 0.2s linear;
  transform: translateX(-50%);

  .item:hover & {
    opacity: 1;
  }
}
