@import '../../styles/variables';

.root {
  border: 1px solid $lightCoolGray;
  border-radius: 3px;
}

.textWrapper {
  padding: 10px;
  align-items: center;
  display: flex;
}

.link {
  color: $black;
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.image {
  display: block;
  height: 5rem;
  width: 5rem;
  object-fit: cover;
}

.title {
  margin: 0;
  word-break: break-word;

  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  font-weight: 900;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.readMore {
  margin-left: auto;
  text-align: right;
  display: flex;
  align-items: center;

  &::after {
    display: inline-block;
    margin-left: 4px;
    content: '→';
    font-size: 42px;
    padding-right: 4px;
  }
}
