@import '../../styles/_colors';

.root {
  padding: 1rem;
  background-color: $yellow;
  display: flex;
  justify-content: center;
}

.text {
  font-weight: 700;
}
