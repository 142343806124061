.root {
  padding: 20px;
}

.title {
  margin-top: 0;
}

.actions {
  text-align: right;

  & :last-child {
    margin-left: 10px;
  }
}
