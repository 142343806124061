.summaryList {
  margin-bottom: 40px;

  li {
    margin: 10px 0;
  }
}

.detailList {
  list-style: none;
  margin: 10px 0 20px;
  padding: 0;
}

.count {
  color: red;
  font-weight: 700;
}
