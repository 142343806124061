@use "sass:map";
@import '../../styles/variables';

.textEditorAndHeader {
  padding: 0.75rem 0.75rem 0.5rem;
  background-color: $white;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 20px 20px 10px;
  }
}

.actions {
  padding: 10px 20px;
  margin-top: 1px;
  text-align: right;
  background-color: $white;
  border-radius: 0 0 20px 20px;
}

.header {
  margin-top: 0;
  margin-bottom: 20px;
}

.cancelButton {
  margin-right: 10px;
}

.lengthWarning {
  padding-top: 5px;
  font-size: 13px;
  font-weight: 700;
  color: $redDark;
  margin-top: -20px;
  margin-bottom: 0;
}
