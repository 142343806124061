@import '../../styles/_colors';
@import '../../styles/_mixins';

.root {
  display: flex;
  & > * + * {
    margin-left: 0.5rem;
  }
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.isLoading {
  display: none;
}

.link {
  position: relative;
  display: inline-block;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  appearance: none;
  background: none;
  border-radius: 0;
  color: $direktcenterColor;
  font-weight: 700;

  &:hover,
  &:active {
    background: none;
    text-decoration: underline;
  }
}

.image {
  width: 100%;
  height: 100%;
}

.imageWrapper {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  &:focus-within + .textWrapper > label {
    @include focusOutline;
  }
}

.textWrapper {
  & > * {
    display: block;
  }
  & > * + * {
    margin-top: 0.5rem;
  }
}

.linkButton {
  color: $direktcenterColor;
  cursor: pointer;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
}

.fileUploader {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
}

.mdAddAPhoto,
.mdDelete {
  width: 1.125rem;
  height: 1.125rem;
  vertical-align: bottom;
  margin-right: 6px;
}

.avatarIcon {
  width: 100%;
  height: 100%;
}
