@import '../../styles/variables';

.root {
  margin: 60px 0 20px;
  clear: both;

  &:first-child {
    margin-top: 0;
  }
}

.title {
  display: inline-block;
  padding: 7px 10px;
  margin: 0;
  font-family: monospace;
  color: $white;
  background: $extraDarkCoolGray;
}
