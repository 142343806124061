@import '../../styles/variables';

.root {
  position: absolute;
  z-index: $z-index-notifications-overlay;
}

.closeButton {
  position: absolute;
  top: 20px;
  left: 20px;

  svg {
    height: 0.75rem;
    width: 0.75rem;
    margin-top: 0.2rem;
  }
}
