@import '../../styles/variables';

.root {
  right: 0;
  display: none;
  width: auto;
  max-width: 18rem;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: $white;
  box-shadow: 0 4px 4px rgba($color: $black, $alpha: 0.2);
}

.isOpen {
  display: block;
}

.item {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  color: $black;
  background: white;
  border-top: 1px solid $grayLight;
  border-left: 1px solid $grayLight;
  border-right: 1px solid $grayLight;
  cursor: pointer;
  appearance: none;
  position: relative;

  &:hover {
    background: $graySuperLight;
  }

  &:last-child {
    border-bottom: 1px solid $grayLight;
  }
}

.danger {
  color: $white;
  background: $redDark;

  &:hover {
    background: $redExtraDark;
  }
}

.disabled {
  color: $grayLight;
  cursor: default;
}
