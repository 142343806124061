@import '../../styles/variables';

.root {
  border-collapse: collapse;
  //   width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  //   table-layout: fixed;

  td,
  th {
    padding: 0.75rem 4px;
    text-align: left;
    border-top: 1px solid $grayExtraLight;
    border-bottom: 1px solid $grayExtraLight;
  }

  tr:hover {
    background-color: $grayExtraLight;
  }

  thead th {
    font-size: 0.75rem;
    font-weight: normal;
    color: $grayDark;
    vertical-align: bottom;
    border: 0;
    cursor: pointer;
  }

  .idCell {
    text-align: right;
  }
}

time {
  white-space: nowrap;
}

.truncated {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset -14px 0px 10px -10px #aaa;
  margin: -12px 2px;
  padding: 12px 0;
  min-height: 40px;
}

.avatarImageContainer {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-right: 0.25rem;
}

.avatarImage {
  background: $white;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
