@import '../../styles/variables';

.root {
  border-spacing: 0;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  table-layout: fixed;

  .published {
    color: green;
    font-weight: bold;
  }
  .pending {
    color: orange;
    font-weight: bold;
  }
  .threat,
  .deleted {
    color: red;
    font-weight: bold;
  }
  .blockedAuto,
  .blockedCustom {
    color: orangered;
    font-weight: bold;
  }

  td,
  th {
    padding: 0.5rem 4px;
    text-align: left;
    vertical-align: top;
    color: gray;
  }

  tr:first-child td {
    padding-top: 1.5em;
  }
  tr:last-child td {
    padding-bottom: 1.5em;
    border-bottom: 1px solid $grayLight;
  }

  tbody:hover {
    background-color: $grayExtraLight;
  }

  thead th {
    font-size: 0.75rem;
    color: black;
    font-weight: normal;
    vertical-align: bottom;
    border: 0;
    border-bottom: 1px solid $black;
    cursor: pointer;
  }

  .idCell {
    width: 6em;
  }

  .mainTextCell {
    color: black;
    font-size: 1rem;
  }
}

time {
  white-space: nowrap;
}

.visitorIcon {
  width: 1em;
  display: inline-block;
  margin-left: 0.5em;
}

.tooltipTrigger {
  position: relative;
}

.tooltip {
  position: absolute;
  bottom: -42px;
  left: 30px;
  z-index: $z-index-content-low;
  opacity: 0;
  transition: opacity 0.2s linear;
  transform: translateX(-50%);

  .tooltipTrigger:hover & {
    opacity: 1;
  }
}
