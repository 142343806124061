/* Animations in CSS modules was not so fun. More details here:
   https://gravitydept.com/blog/keyframe-animations-in-css-modules */

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  #live-icon-area :global {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: area;
    animation-timing-function: ease-out;
    stroke: none;
    transform-origin: center center;
  }

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  #live-icon-ring :global {
    animation-duration: 2s, 2s;
    animation-iteration-count: infinite, infinite;
    animation-name: ring, ring-fade;
    animation-timing-function: ease-out, ease-out;
    fill: none;
    stroke: transparent;
    stroke-width: 1px;
    transform-origin: center center;
  }

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  #live-icon-center :global {
    animation: none;
    opacity: 1;
    stroke: none;
  }
}

@keyframes :global(ring) {
  from {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes :global(ring-fade) {
  2% {
    opacity: 1;
    stroke: currentColor;
  }
  40% {
    opacity: 0.6;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    stroke: currentColor;
  }
}

@keyframes :global(area) {
  from {
    opacity: 1;
    transform: scale(0.1);
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
