@import '../styles/variables';

$pendingPostAreaWidth: 25rem;

.root {
  width: 100%;
  max-width: 32rem;
  margin: 0 auto;
}

.createPostEditor {
  margin-bottom: 20px;
}

.disasterstreamMessageWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  padding: 0px 16px 0;
  color: $black;
  background: $redExtraLight;
}

.disasterstreamMessage {
  padding: 20px 20px 20px 0;
}

.infoIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-top: 20px;
}

.header {
  flex: 0 0 auto;
  padding: 0 20px;
  line-height: 40px;
  background: $white;
  border-bottom: 10px solid $graySuperLight;
}

.headerTitle {
  margin: 0;
  font-size: 20px;
}

.filter {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.toggleLiveBroadcastWrapper {
  background: $white;
  margin-bottom: 16px;
  padding: 20px;
  gap: 8px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: $black;
  color: $white;
  border-radius: 8px;
}

.toggleLiveBroadcastHeadlineWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.toggleLiveBroadcastHeadline {
  margin: 10px 0 6px;
  font-weight: 700;
  font-size: 1.125rem;
}

.toggleLiveBroadcastLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.liveVideoIcon {
  margin-right: 8px;
}

.toggleLiveBroadcastSubline {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
}

.toggleLiveBroadcastSublineButton {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  text-align: inherit;
  display: inline-block;
  line-height: 0;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.editPen {
  color: $graySuperLight;
  height: 16px;
  width: 16px;
}

.liveBadge {
  background-color: $yellowAccent;
  padding: 2px 4px;
  font-weight: 600;
  font-size: 0.875rem;
  border-radius: 2px;
  color: $black;
}

.tooltip {
  position: absolute;
  top: 25px;
  left: 16px;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;

  .toggleLiveBroadcastSubline:hover & {
    opacity: 1;
  }
}
