@use "sass:map";
@import '../../styles/variables';

.root {
  position: absolute;
  bottom: -38px;
  left: 50%;
  z-index: $z-index-content-low;
  width: 230px;
  font-weight: 700;
  line-height: 32px;
  border: none;
  border-radius: 16px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: opacity 0.2s linear;
  transform: translateX(-50%);

  &:disabled {
    pointer-events: none;
    opacity: 0;
  }

  @media (min-width: map.get($breakpoints, medium)) {
    bottom: -30px;
  }
}

.scrolledDown {
  background: $yellowLight;
}

.manuallyPaused {
  background: $redLight;
}
