@import '../../styles/colors';

@import '../../styles/variables';

.root {
  padding: 20px;
  background: $white;
  margin-bottom: 2rem;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  appearance: none;
  border: 0;
  background: transparent;
}

.closeIcon {
  width: 0.75rem;
  height: 0.75rem;
}
