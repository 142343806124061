@import '../../../styles/variables';

.root {
  position: relative;
  margin-bottom: 3px;
  background: $white;
  border-bottom: 1px solid transparent;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    position: absolute;
    top: 20px;
    right: -20px;
    width: 0;
    height: 0;
    content: '';
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid $white;
  }
}

.isEditing {
  background: none;
}

.content {
  padding: 20px;
  padding-bottom: 0;
}

.meta {
  font-size: 0.8rem;
  color: $grayDark;
}

.avatar {
  float: left;
  width: 30px;
  height: 30px;
  margin-top: -6px;
  margin-right: 5px;
  background: $grayLight;
  border-radius: 15px;
}

.actionsWrapper {
  float: right;
  margin: 8px;
}
