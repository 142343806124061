@use "sass:map";
@import '../../styles/variables';

.root {
  display: none;

  @media (min-width: map.get($breakpoints, medium)) {
    position: relative;
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5em;
    color: $white;
    white-space: break-spaces;
    pointer-events: none;
    background: $black;
    text-indent: 0;
    min-width: 100px;

    &::after {
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -5px;
      pointer-events: none;
      content: '';
      border: solid transparent;
      border-color: rgba($black, 0);
      border-width: 5px;
      border-bottom-color: $black;
    }
  }
}
