@use "sass:map";
@import '../styles/variables';
@import '../styles/colors';

.root {
  max-width: 24rem;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.16);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: map.get($breakpoints, small)) {
    max-width: unset;
  }

  @media (min-height: 720px) and (min-width: map.get($breakpoints, small)) {
    margin-top: 100px;
  }

  @media (min-height: 1000px) and (min-width: map.get($breakpoints, small)) {
    margin-top: 250px;
  }
}

.loggedInAs {
  font-weight: 700;
  color: $redDark;
  margin-top: 1.5em;
}

.wrapper {
  @media (min-width: map.get($breakpoints, small)) {
    display: flex;
    flex-direction: row;
  }
}

.bylineWrapper {
  display: flex;
  flex-direction: column;
}

.byline {
  background-color: $black;
  color: $white;
  flex-grow: 1;

  padding: 1rem 0.75rem;
  text-align: center;

  @media (min-width: map.get($breakpoints, small)) {
    padding: 1.25rem;
    min-width: 280px;
  }
}

.bylineHeader {
  background-color: $loginPageBylineHeaderCoolGray;
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 8px;
  line-height: 13px;
  text-transform: uppercase;
  display: flex 1;
  justify-items: center;
  align-items: center;
  padding: 4px;
}

.image {
  margin: 16px auto 0 auto;
}

.displayName {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 16px;
}

.guestTitle {
  font-size: 14px;
  line-height: 16px;
}

.subTitle {
  font-size: 18px;
  line-height: 1.3;

  margin-bottom: 0;
}

.content {
  padding: 1.5rem 0.75rem;

  @media (min-width: map.get($breakpoints, small)) {
    padding: 2.5rem 32px;
  }
}

.title {
  margin-top: 0;
}

.intro {
  @media (min-width: map.get($breakpoints, small)) {
    font-size: 20px;
    line-height: 1.2;
  }
}

.list {
  margin: 8px 0 0 0;
  padding-inline-start: 20px;

  & > li {
    font-size: 16px;
    line-height: 1.5;
  }
}

.continueButton {
  margin-top: 1.5rem;
}

.banner {
  margin-top: 1.5rem;
}
