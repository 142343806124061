@import '../../styles/_colors';

.headingWrapper {
  display: flex;
  padding: 20px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.isNotHighlightsBoxVisible {
  background: $yellowExtraLight;
}

.headingLeft {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
}

.heading {
  font-weight: 400;
  margin: 0;
}

.button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  outline: none;
  text-align: left;
  text-decoration: none;
  color: $grayDarker;
}

.buttonIconUp,
.buttonIconDown {
  margin-left: 0.5rem;
  width: 0.75rem;
  transition: all 0.2s;
  fill: $grayDarker;
}

.buttonIconUp {
  transform: rotate(-180deg);
}
