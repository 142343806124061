@import '../../styles/variables';

.root {
  position: relative;
  padding-bottom: 1px; // Save us from collapsing margins
  margin-bottom: 20px;
  background: $white;
}

.isDiscussion::after {
  position: absolute;
  top: 53px;
  left: -20px;
  width: 0;
  height: 0;
  content: '';
  border-top: 15px solid transparent;
  border-right: 20px solid $white;
  border-bottom: 15px solid transparent;
}

.post {
  position: relative;
}

.isVisitor {
  background: $extraLightCoolGray;

  &.isDiscussion::after {
    border-right-color: $extraLightCoolGray;
  }
}

.isEditing {
  background: none;
}

.rootPinned {
  background-color: $purpleExtraLight;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.125rem;
    height: 100%;
    z-index: $z-index-content-low;
    background-color: $purpleDark;
  }
}

.rootHighlighted {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.125rem;
    height: 100%;
    z-index: $z-index-content-low;
    background-color: $redDark;
  }
}

.timestamp {
  position: relative;
  padding: 20px 62px 20px 40px;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
}

.timestampCircle {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 10px;
  height: 10px;
  margin-top: -1px;
  background: $redDirektcenter;
  border-radius: 5px;
  transform: translateY(-50%);

  .isVisitor & {
    background: $extraDarkTurquoise;
  }
}

.pin {
  position: relative;
  padding: 20px 62px 20px 20px;
  line-height: 12px;
}

.pinCircle {
  background: $purpleDark;
  position: relative;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;

  .pinIcon {
    height: 0.625rem;
    position: relative;
    top: 0.375rem;
    width: 100%;
  }
}

.meta {
  padding: 20px 0;
  font-size: 0.8em;
}

.content {
  flex-grow: 1;
}

.postContent {
  position: relative;
  padding: 0 20px 0;
}

.actionsWrapper {
  position: absolute;
  top: 8px;
  right: 8px;
}

.updatePostEditorWrapper {
  position: relative;
  margin-bottom: 20px;
}

.createReplyEditorWrapper {
  padding: 0 20px;
  &:last-child {
    padding-bottom: 20px;
  }
}

.warning {
  height: 16px;
  color: $orangeRegular;
  position: relative;
  padding: 8px;
  margin-right: -8px;
}

.help {
  height: 16px;
  color: $grayDarker;
  position: relative;
  padding: 8px;
  margin-right: -8px;
}

.tooltip {
  position: absolute;
  z-index: $z-index-content-higher;
  opacity: 0;
  transition: opacity 0.2s linear;
  right: -35px;
  top: 30px;
  min-width: 190px;

  &::after {
    left: unset;
    right: 46px;
  }

  .warning:hover & {
    opacity: 1;
  }

  .help:hover & {
    opacity: 1;
  }
}
