@use "sass:map";
@import '../../styles/variables';

.textEditorAndHeader {
  position: relative;
  padding: 0.75rem 0.75rem 0;
  background-color: $white;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 20px 20px 0;
  }
}

.attachment {
  padding: 0 0.75rem 0.25rem;
  background-color: $white;

  @media (min-width: map.get($breakpoints, medium)) {
    padding: 0 20px 10px;
  }
}

.actions {
  padding: 10px 20px;
  border-top: 1px solid $lightCoolGray;
  text-align: right;
  background-color: $white;
  border-radius: 0 0 16px 16px;
}

.header {
  margin-top: 0;
  margin-bottom: 20px;
}

.cancelButton {
  margin-right: 10px;
}

.textLengthWarning {
  padding-top: 5px;
  font-size: 13px;
  font-weight: 700;
  color: $redDark;
  margin-bottom: 0;
}

.byline {
  background-color: $white;
  padding: 0 20px 10px;
  font-size: 10px;
  line-height: 1.625rem;
  color: $black;
}

.bylineButton {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.bylineRole {
  font-weight: 700;
  &::after {
    content: '•';
    margin: 0 3px;
  }
}

.bylineText {
  text-align: left;
  line-height: 1.25em;
}
