@import '../../styles/variables';

@mixin postPseudoHeadingStyle {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  font-weight: 700;
  &.indent {
    padding-left: 15px;
    border-left: 5px solid $grayLight;
  }
}

.body {
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.38;
  word-wrap: break-word;

  &.indent {
    padding-left: 15px;
    border-left: 5px solid $grayLight;
  }
}

.heading {
  @include postPseudoHeadingStyle();
}

.heading + .body {
  padding-top: 0.625rem;
}

.attachment {
  margin-bottom: 20px;
}
